import Vue from 'vue'
import App from './App.vue'
import SocketPlugin from './plugins/socketPlugin'
import ParseDevice from './plugins/devicePlugin'
import VueQRCodeComponent from 'vue-qrcode-component'
import { EventBus } from './plugins/eventPlugin'

Vue.config.productionTip = false
import './css/style.css'

global.EventBus = EventBus
Vue.prototype.$screen = false;

new Vue({
  el: '#app',
  render: h => h(App),
  data() {
    return {
     screen: false
    }
  },
  mounted(){
    console.log('dev test1')
    // maybe convert parse and make it return callback so emit comes after parse?
    ParseDevice.parse()

    this.$screen = {device: window.Device, network: window.Network, storage: window.ScreenStorage}

    let host = 'backend-worker.saloustrosm.workers.dev';
    const uid = this.$screen.device.serial();

    const token = '$ign@g3dev1Ce';
    let connectionUrl = `wss://${host}/screen?uid=${uid}&token=${token}`
    if(process.env.NODE_ENV === 'development'){
      host = 'localhost'
      connectionUrl = `ws://${host}:8787/screen?uid=${uid}&token=${token}`
    }
    Vue.use(SocketPlugin, { connectionUrl: connectionUrl})

    Vue.component('qr-code', VueQRCodeComponent)
    EventBus.$emit('appMounted')

    this.initSocketListeners()
    EventBus.$on('connected',function(){
      if (this.$socket.readyState === WebSocket.OPEN) {
        this.sendReport('connectionEstablished')
      } 
    }.bind(this))
  },
  methods:{
    initSocketListeners(){
      console.log('init listeners')
      this.$socket.onmessage = (event) => {
        //console.log('Message received:', event.data);
        //EventBus.$emit('messageReceived', event.data);
        const res = JSON.parse(event.data)
        if(res.event==='welcome' || res.event==='inserted'){
          const dbValue = res.data.value
          console.log(dbValue)
          if(dbValue.associated_site==null){
            EventBus.$emit('showPage', ({page:'Register', association_number:dbValue.association_number}))
          }
          else{
            EventBus.$emit('showPage', ({page:'Main', association_number:dbValue.association_number}))
          }
        }
        else if(res.event==='report'){
          this.sendReport('submitReport')
        }
        else if(res.event==='otherEvent'){

        }
      };
    },
    sendReport(reason){
      const message = JSON.stringify({
        event: reason,
        data: {
          uid: this.$screen.device.serial(),
          panel: this.$screen.device.panel(),
          volume: this.$screen.device.volume(),
          vendor: this.$screen.device.vendor(),
          model: this.$screen.device.model(),
          firmware: this.$screen.device.firmware(),
          userAgent: this.$screen.device.browser(),
          width: this.$screen.device.width(),
          height: this.$screen.device.height(),
          mac: this.$screen.network.mac(),
          ip: this.$screen.network.ip(),
          gateway: this.$screen.network.gateway(),
          dns: this.$screen.network.dns(),
          used: this.$screen.storage.used(),
          total: this.$screen.storage.total()
        },
      });
      console.log('send the submitReport')
      this.$socket.send(message);
    }
  }
})
