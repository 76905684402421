var device = {} 
var storage = {}
var network = {}

;(function($) {
  'use strict'

  /* Public Functions */
  var ParseDevice = {
    parse(){
      console.log('parsing device...')
      getDevice(function(){
        deviceDetails()
        networkDetails()
        storageDetails()
      })
    }
  }

  function getDevice(callback) {
    //console.log(navigator.userAgent)
    if (navigator.userAgent.indexOf('Maple') >= 0) {    // Samsung ORSAY
      device = require('./devices/samsung/samsung')
      storage = require('./devices/samsung/storage')
      network = require('./devices/samsung/network')
      callback()
    }
    else if (navigator.userAgent.indexOf('PhilipsHTV') >= 0) {     // Philips Android TVs
      console.log('Philips android detected!')
      device = require('./devices/philips/philips')
      storage = require('./devices/philips/storage')
      network = require('./devices/philips/network')
      callback()
    }
    else if (navigator.userAgent.indexOf('LGE') >= 0) {     // LG New TVs
      console.log('LG detected!')
      require('./devices/lg/hcap')
      device = require('./devices/lg/lg')
      storage = require('./devices/lg/storage')
      network = require('./devices/lg/network')
      callback()
    }
    else if (navigator.userAgent.indexOf('Tizen') >= 0) {
      var tizenVersion = Number(window.navigator.userAgent.split('Tizen ')[1].split(')')[0])
      console.log('Samsung Tizen '+tizenVersion+' platform detected!')
      device = require('./devices/tizen/tizen')
      storage = require('./devices/tizen/storage')
      network = require('./devices/tizen/network')
      callback()
    }
    else if (navigator.userAgent.indexOf('HDSSTB') >= 0) {     // Android Digital Signage STB
      console.log('Hoteplus Digital Signage STB detected!')
      device = require('./devices/hdsstb/hdsstb')
      storage = require('./devices/hdsstb/storage')
      network = require('./devices/hdsstb/network')
      callback()
    }
    // WebOS 3.2 Mozilla/5.0 (Linux; NetCast; U) AppleWebKit/537.31 (KHTML, like Gecko) Chrome/38.0.2125.122 Safari/537.31 SmartTV/7.0
    else if (navigator.userAgent.indexOf('SmartTV') >= 0 || navigator.userAgent.indexOf('Large Screen') >= 0) {         // LG Signage WebOS
      console.log('LG Signage WebOS platform detected!')
      device = require('./devices/webOS/webos')
       storage = require('./devices/webOS/storage')
      network = require('./devices/webOS/network')
      callback()
    }
    else if (navigator.userAgent.indexOf('Raspberry') >= 0) {     // Raspberry
      console.log('Raspberry detected')
      device = require('./devices/raspberry/raspberry')
      storage = require('./devices/raspberry/storage')
      network = require('./devices/raspberry/network')
      callback()
    }
    else {
      console.log('Browser detected')
      device = require('./devices/browser/browser')
      storage = require('./devices/browser/storage')
      network = require('./devices/browser/network')
      callback()
    }
  }

  function deviceDetails() {
    device.init(function(device) {
      return device
    })
  }
  function storageDetails() {
    storage.init(function(storage) {
      return storage
    })            
  }
  function networkDetails() {
    network.init(function(network) {
      return network
      /*if(error) return callback(error, null)
      callback(null, network)*/
    })            
  }
  module.exports = ParseDevice

})()