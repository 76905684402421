import { EventBus } from './eventPlugin';

const SocketPlugin = {
  install(Vue, options) {
    let socket;
    let reconnectInterval = options.reconnectInterval || 5000; // Retry every 5 seconds
    let isConnected = false;

    const connect = () => {
      console.log('Attempting to connect to WebSocket...');
      socket = new WebSocket(options.connectionUrl);

      // Attach socket to Vue prototype
      Vue.prototype.$socket = socket;

      // Connection opened
      socket.onopen = () => {
        isConnected = true;
        console.log('Connected to server (socket plugin)');
        EventBus.$emit('connected');
      };

      // Connection closed
      socket.onclose = (event) => {
        isConnected = false;
        console.log('WebSocket closed:', event);
        EventBus.$emit('disconnected');

        // Attempt to reconnect
        if (!event.wasClean) {
          console.log(`Reconnecting in ${reconnectInterval / 1000} seconds...`);
          setTimeout(connect, reconnectInterval);
        }
      };

      // Connection error
      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

    };
    connect();
  }
};

export default SocketPlugin;