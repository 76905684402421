;(function($) {
  'use strict';

  // Global Configuration 
  var device = {
    keys: {}
  };

  var panelStatus = true

  var Device = {

    init: function(callback) {
      console.log('getting browser details')
      this.setKeys()
      initDeviceStaticParams()
            
      typeof callback === 'function' && callback(this)
      return this;
    },

    log: function(s){
      console.log(s)
    },

    keys: function() {
        return device.keys
    },
    
    width: function() {
      return window.innerWidth
    },
        
    height: function() {
      return window.innerHeight 
    },
        
    browser: function() {
      return navigator.userAgent;
    },
    
    firmware: function() {
      return 'Unknown'
    },

    vendor: function() {
      let agent = window.navigator.userAgent.toLowerCase()
      if (agent.indexOf("edg") > -1) {
        return 'MS Edge'
      } 
      else if (agent.indexOf("opr") > -1 && !!window.opr) {
        return 'Opera'
      } 
      else if (agent.indexOf("chrome") > -1 && !!window.chrome) {
        return 'Chrome'
      } 
      else if (agent.indexOf("firefox") > -1) {
        return 'Firefox'
      } 
      else if (agent.indexOf("safari") > -1 && agent.indexOf("chrome") === -1) {
        return 'Safari'
      } 
      return 'Browser'
    },

    model: function() {
      return 'Browser'
    },

    serial: function() {
      return '001122445566'
    },
    
    volume: function(volume) {
      if(volume){
        return volume
      }
      return '0'
    },

    mute: function(mute) {
      return false
    },

    remote: function(key) {
      global.vm.$root.triggerKeyboardEvent(document, key)
    },

    updateHoteplus: function() {
      return true
    },

    updateConfiguration: function(config) {
      return true
    },

    reboot: function(){
      window.location.reload()
    },

    panel: function(p) {
      if(p==true || p==false){
        console.log('set panel status')
        panelStatus=p
        return panelStatus
      }
      return panelStatus
    },

    source: function(source) {
      return false
    },
    
    setKeys: function() {
      device.keys = {
        RIGHT: 39,
        LEFT: 37,
        UP: 38,
        DOWN: 40,
        RETURN: 8,
        EXIT: 27,
        ENTER: 13,
        HOME: 36,
      }
    }
  }
      
  // Private Functions 
window.Device = Device;

function initDeviceStaticParams() {
  var deviceInfo = { }
  deviceInfo['panel'] = panelStatus
  deviceInfo['vendor'] = Device.vendor()
  deviceInfo['browser'] = Device.browser()
  deviceInfo['firmware'] = Device.firmware()
  deviceInfo['model'] = Device.model()
  deviceInfo['serial'] = Device.serial()
}

function fireKey(el,key){
  if(document.createEventObject){
    var eventObj = document.createEventObject()
    eventObj.keyCode = key
    el.fireEvent("onkeydown", eventObj)
    eventObj.keyCode = key
  }
  else if(document.createEvent){
    var eventObj = document.createEvent("Events")
    eventObj.initEvent("keydown", true, true)
    eventObj.which = key
    eventObj.keyCode = key
    el.dispatchEvent(eventObj)
  }
} 

module.exports = Device

})();