;(function($) {
    'use strict';

    /************************/
    /* Global Configuration */
    /************************/
    var storage = {
    };
    
    var downloadQueue = []
    var production = process.env.NODE_ENV === 'production'

    /********************/
    /* Public Functions */
	/********************/
    var Storage = {
        // Initialize function
		init: function(callback) {
            this.FS = new FileSystem();

            if (!this.FS.isValidCommonPath(curWidget.id)) {
                this.FS.createCommonDir(curWidget.id);
            }
            
            this.data = this.readData();
            this.createDir('dist')
            this.createDir('contents')
            downloadFromQueue()
            
			if (callback) {
				return callback(this);
            }
        },
    
        //#region Low Level Storage Functions

        // Set value to the storage
        // PARAMS: {String} name, {Object/String/Number} value
        // RETURNS: {Boolean}
        set: function(name, value) {
            this.data[name] = value;
	        return this.writeData(this.data);
        },
        
        // Get value from the storage
        // PARAMS: {String} name
        // RETURNS: {Object/String/Number} or FALSE 
        get: function(name) {
            return this.data[name];
        },

        // Clear all stored data
        // RETURNS: {Boolean}
        clear: function(){
            this.data = {};
	        return this.writeData(this.data);
        },

        // Create folder
        // RETURNS: {Boolean}
        createDir: function(path) {
            if(!this.dirExists(path)) return this.FS.createCommonDir(curWidget.id + '/' + path);
            return true
        },

        // Delete folder
        // RETURNS: {Boolean}
        deleteDir: function(path) {
            if(this.dirExists(path)) return this.FS.deleteCommonDir(curWidget.id + '/' + path);
            return false
        },

        // Delete file
        // RETURNS: {Boolean}
        deleteFile: function(path) {
            if(this.fileExists(path)) return this.FS.deleteCommonFile(curWidget.id + '/' + path);
            return false
        },

        // Check if folder exists
        // RETURNS: {Boolean}
        dirExists: function(path) {
            return this.FS.isValidCommonPath(curWidget.id + '/' + path) === 1 ? true : false
        },

        // Check if file exists
        // RETURNS: {Boolean}
        fileExists: function(path) {
            var handle;

            try {
                handle = this.FS.openCommonFile(curWidget.id + '/' + path, 'r');
                if (handle) {
                    this.FS.closeCommonFile(handle);
                    return true
                }
                else return false
            } 
            catch (e) { return false }
        },

        //#endregion Low Level Storage Functions
                
        offlineMode() { return !!!window.location.hostname },
        serverHost() { return window.location.hostname || this.get('serverHost') },
        serverPort() { return !production ? 80 : window.location.port || this.get('serverPort') || 80 },
        serverProtocol() { return window.location.protocol !== 'file:' ? window.location.protocol || this.get('serverProtocol') : this.get('serverProtocol') },
        serverURL() { return !production ? this.serverProtocol() + '//' + this.serverHost() : this.serverProtocol() + '//' + this.serverHost() + ':' + this.serverPort() },
        mobilePath() { return !production ? this.serverProtocol() + '//' + this.serverHost() + ':8082' : this.serverProtocol() + '//' + this.serverHost() + ':' + this.serverPort() + '/mobile' },
        //assetsPath() { return this.offlineMode() ? 'file:///mtd_down/common/HospitalityBrowser/contents/' : this.serverURL() + '/contents/' },
        assetsPath() { return this.serverURL() + '/contents/' },
        proxyPath() { return this.serverProtocol() + '//' + this.serverHost() + ':1' + this.serverPort() + '/fetch/' },

        // Downloads file to local storage
        // RETURNS: {Boolean}
        downloadFile: function(src, dst, callback) {
            DOWNLOADER.StartDownFile(src, '/mtd_down/common/HospitalityBrowser/' + dst)
            DOWNLOADER.OnComplete = function(param) {
                if(param.split('?')[0] === '1000' && param.split('?')[1]) return callback(true)
                else if(param.split('?')[0] === '1000' && param.split('?')[0]) return callback(false)
            }
        },

        // Handles downloading application to local storage for offline operation
        // RETURNS: {Boolean}
        storeAppOffline: function(callback) {
            downloadQueue.push({src: this.serverURL() + '/tv/index.html', dst: 'offline.html', filename: 'offline.html', isApp: true })
            downloadQueue.push({src: this.serverURL() + '/tv/build.js', dst: 'build.js', filename: 'build.js', isApp: true })
            downloadQueue.push({src: this.serverURL() + '/tv/worker.js', dst: 'worker.js', filename: 'worker.js', isApp: true })
            downloadFromQueue()
            callback(null, true)
        },

        // RETURNS: {Boolean}
        removeAppOffline: function() {
            this.deleteFile('offline.html')
            this.deleteFile('build.js')
            this.deleteFile('worker.js')
        },

        // Handles downloading project static assets to local storage for offline operation
        // RETURNS: {Boolean}
        storeProjectDataOffline: function(projectObj, callback) {
            /*var projectAssets = []
            for(var i = 0; i < Object.keys(projectObj.pages).length; i++) {
                var page = projectObj.pages[Object.keys(projectObj.pages)[i]]

                // get page background image
                if(!!page.styles['background-image'] && page.styles['background-image'] !== '' && projectAssets.indexOf(page.styles['background-image']) === -1) 
                    projectAssets.push(page.styles['background-image'].split('/')[page.styles['background-image'].split('/').length - 1].replace(')', ''))

                // get page elements images
                for(var j = 0; j < Object.keys(page.elements).length; j++) {
                    var element = page.elements[Object.keys(page.elements)[j]]
                    // for all element types background images
                    if(!!element.styles['background-image'] && element.styles['background-image'] !== '' && projectAssets.indexOf(element.styles['background-image']) === -1) {
                        projectAssets.push(element.styles['background-image'].split('/')[element.styles['background-image'].split('/').length - 1].replace(')', ''))
                    }
                    // for imageElement type elements, slide images
                    if(element.type === 'imageElement' && element.action.value.images.length > 0) {
                        for(var k = 0; k < element.action.value.images.length; k++) {
                            if(projectAssets.indexOf(element.action.value.images[k]) === -1) 
                                projectAssets.push(element.action.value.images[k])
                        }
                    }
                }
            }
            
            var storedAssets = this.get('projectAssets') ? this.get('projectAssets') : []
            // Create an array for fresh assets to download in local storage
            var newAssets = []
            for(var i = 0; i < projectAssets.length; i++) {
                if(storedAssets.indexOf(projectAssets[i]) === -1) newAssets.push(projectAssets[i])
            }
            // Delete old assets
            var i = 0;
            while(i < storedAssets.length) {
                if(projectAssets.indexOf(storedAssets[i]) === -1) {
                    console.info('Deleting old asset: ' + storedAssets[i])
                    this.deleteFile('contents/' + storedAssets[i])
                    storedAssets.splice(i, 1)
                }
                else i++
            }
            this.set('projectAssets', storedAssets)

            // Add new assets to download queue
            for(var i = 0; i < newAssets.length; i++) {
                downloadQueue.push({src: this.serverURL() + '/contents/' + newAssets[i], dst: 'contents/' + newAssets[i], filename: newAssets[i], isApp: false })
            }
            downloadFromQueue()*/
            
            callback(null, true)
        },

        // Get data from the Samsung File System
        // PRIVATE
        readData: function() {
            var handle, data;

            try {
                handle = this.FS.openCommonFile(curWidget.id + '/userdata.dat', 'r');
                if (handle) {
                    data = handle.readAll();
                    this.FS.closeCommonFile(handle);
                    data = JSON.parse(data);
                }
            } 
            catch (e) { 
                console.log('Error reading storage data, ' + e)
                return {} 
            }

            return data || {};
        },

        // Store data to the Samsung File System
        // PRIVATE
        writeData: function(data){
            var handle;

            try {
                handle = this.FS.openCommonFile(curWidget.id + '/userdata.dat', 'w');

                if (handle) {
                    handle.writeAll(JSON.stringify(data));
                    this.FS.closeCommonFile(handle);
                }
            } 
            catch (e) { 
                console.log('Error writing storage data, ' + e)
                return {}; 
            }
            return true;
        }
    }
        
    /*********************/
    /* Private Functions */
    /*********************/

    function downloadFromQueue() {
        if(downloadQueue.length === 0) return
        var asset = downloadQueue.shift()
        if(typeof asset !== 'object' || asset === null || !('src' in asset) || !('dst' in asset) || !('filename' in asset) || !('isApp' in asset)) {
            console.error('Asset:' + asset + ' is wrong, skiping...')
            return setTimeout(function() { downloadFromQueue() }, 100)
        }
        Storage.downloadFile(asset.src, asset.dst, function(result) {
            if(!result) {
                console.error('Failed to download ' + asset.filename)
            }
            else { 
                if(!asset.isApp) {
                    var storedAssets = Storage.get('projectAssets')
                    storedAssets.push(asset.filename)
                    Storage.set('projectAssets', storedAssets)
                    console.info('Downloaded: ' + asset.filename)
                }
                else console.warn('Downloaded: ' + asset.filename)
            }
            setTimeout(function() { downloadFromQueue() }, 100)
        })
    }

    window.ScreenStorage = Storage;

    if (typeof module === 'object') {
        module.exports = ScreenStorage;
    }
})();